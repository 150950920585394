import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Testimonials from "../components/Testimonials";
import Packages from "../components/Packages";
import { Helmet } from "react-helmet";

const HowToGetOnline = () => {
  return (
    <Layout>
      <Helmet>
        <title>How to get your business online | Sixvees</title>
        <meta
          name="description"
          content="Sixvees have a range of website options to suit every type of business. If you need to build a website, then we can get you online."
        ></meta>
      </Helmet>
      <main>
        <div className="page-header sr-only">
          <h1>How to get online?</h1>
        </div>

        <section className="primary-content">
          <div className="primary-content__content">
            <div className="container">
              <h2>Ready to get your business online?</h2>
              <p>Great news! You are now one step ahead of your competition.</p>
              <p>
                We have a range of options to suit every type of business. Check
                out our website packages below.
              </p>
              <p>
                Not sure what you need? Meet with us for a coffee and we can
                take you through the benefits of each option.
              </p>
            </div>
          </div>
          <div className="icon-scroll" style={{ opacity: 1 }} />
        </section>

        <Packages />

        <section className="feature-grid light-strip-top bg-white text-dark pt-0">
          <div className="container">
            <h2
              className="mb-5 heading-sm ml-auto text-right aos-init aos-animate"
              data-aos="fade-left"
            >
              Why our websites <br />
              <span className="text-dark-stroke text-white">are so good</span>
            </h2>
            <div className="row">
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="thumbs-up"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-thumbs-up fa-w-16 fa-2x"
                    >
                      <path
                        fill="currentColor"
                        d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
                      />
                    </svg>
                    <h5 className="feature__heading">High Quality</h5>
                  </div>
                  <p>
                    We don't cut corners or compromise on our high standards.
                    Our websites are thoroughly tested before they are released
                    into the wild.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="mobile-alt"
                      viewBox="0 0 320 512"
                      className="svg-inline--fa fa-mobile-alt fa-w-10 fa-2x"
                    >
                      <path
                        fill="currentColor"
                        d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"
                      />
                    </svg>
                    <h5 className="feature__heading">Mobile Friendly</h5>
                  </div>
                  <p>
                    Our websites are built to look awesome on any screen size.
                    The images and text will adapt to fit mobile phones,
                    tablets, computers, and everything in between.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="info-circle"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-info-circle fa-w-16 fa-2x"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                      />
                    </svg>
                    <h5 className="feature__heading">Help desk</h5>
                  </div>
                  <p>
                    We will always be there for you when you need support.
                    Remain calm and rest easy knowing that Sixvees has your
                    back.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="chart-line"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-chart-line fa-w-16 fa-lg"
                    >
                      <path
                        fill="currentColor"
                        d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"
                      />
                    </svg>
                    <h5 className="feature__heading">Data Analytics</h5>
                  </div>
                  <p>
                    Our websites come bundled with Google Analytics. See exactly
                    how many people are visiting your website and what pages
                    they are looking at.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="globe"
                      viewBox="0 0 496 512"
                      className="svg-inline--fa fa-globe fa-w-16 fa-lg"
                    >
                      <path
                        fill="currentColor"
                        d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"
                      />
                    </svg>
                    <h5 className="feature__heading">Optimisation</h5>
                  </div>
                  <p>
                    Nobody likes to wait! We optimise our websites to adhere to
                    Google's strict performance standards. This ensures faster
                    loading times and higher search rankings.{" "}
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="undo"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-undo fa-w-16 fa-2x"
                    >
                      <path
                        fill="currentColor"
                        d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"
                      />
                    </svg>
                    <h5 className="feature__heading">Backups</h5>
                  </div>
                  <p>
                    We store automated daily backups of your website in an
                    offsite location. In an emergency, we can quickly restore a
                    backup of your website.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="user"
                      viewBox="0 0 448 512"
                      className="svg-inline--fa fa-user fa-w-14 fa-lg"
                    >
                      <path
                        fill="currentColor"
                        d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                      />
                    </svg>
                    <h5 className="feature__heading">Personalised Setup</h5>
                  </div>
                  <p>
                    We can customise your website specifically to your needs.
                    Not only can we control what the customer sees, but also
                    what the editing experience is like for you and your staff.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="share-alt"
                      viewBox="0 0 448 512"
                      className="svg-inline--fa fa-share-alt fa-w-14 fa-lg"
                    >
                      <path
                        fill="currentColor"
                        d="M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 000-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 00256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z"
                      />
                    </svg>
                    <h5 className="feature__heading">
                      Social Media Integration
                    </h5>
                  </div>
                  <p>
                    We can connect your social media accounts to your website
                    and bridge the gap between all of your online services.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="layer-group"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-layer-group fa-w-16 fa-lg"
                    >
                      <path
                        fill="currentColor"
                        d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 00-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"
                      />
                    </svg>
                    <h5 className="feature__heading">Modern design</h5>
                  </div>
                  <p>
                    Modern design is important to create positive first
                    impressions. Looking better than your competitors will help
                    attract and retain more customers.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="shield-alt"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-shield-alt fa-w-16 fa-2x"
                    >
                      <path
                        fill="currentColor"
                        d="M466.5 83.7l-192-80a48.15 48.15 0 00-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z"
                      />
                    </svg>
                    <h5 className="feature__heading">Secure</h5>
                  </div>
                  <p>
                    Our websites are hosted on state of the art New Zealand
                    based servers. Every website comes with an SSL certificate
                    to secure the website from attackers.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="expand-arrows-alt"
                      viewBox="0 0 448 512"
                      className="svg-inline--fa fa-expand-arrows-alt fa-w-14 fa-lg"
                    >
                      <path
                        fill="currentColor"
                        d="M448 344v112a23.94 23.94 0 01-24 24H312c-21.39 0-32.09-25.9-17-41l36.2-36.2L224 295.6 116.77 402.9 153 439c15.09 15.1 4.39 41-17 41H24a23.94 23.94 0 01-24-24V344c0-21.4 25.89-32.1 41-17l36.19 36.2L184.46 256 77.18 148.7 41 185c-15.1 15.1-41 4.4-41-17V56a23.94 23.94 0 0124-24h112c21.39 0 32.09 25.9 17 41l-36.2 36.2L224 216.4l107.23-107.3L295 73c-15.09-15.1-4.39-41 17-41h112a23.94 23.94 0 0124 24v112c0 21.4-25.89 32.1-41 17l-36.19-36.2L263.54 256l107.28 107.3L407 327.1c15.1-15.2 41-4.5 41 16.9z"
                      />
                    </svg>
                    <h5 className="feature__heading">Room to grow</h5>
                  </div>
                  <p>
                    Your website will need to grow alongside your business. Our
                    websites are built with the future in mind. When you need
                    more features or pages, it's not a problem.
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <div className="feature">
                  <div className="feature__header">
                    {/*?xml version="1.0" encoding="UTF-8"?*/}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="edit"
                      viewBox="0 0 576 512"
                      className="svg-inline--fa fa-edit fa-w-18 fa-2x"
                    >
                      <path
                        fill="currentColor"
                        d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                      />
                    </svg>
                    <h5 className="feature__heading">Simple Editing</h5>
                  </div>
                  <p>
                    Maintaining and editing your website will be easy. With a
                    bit of training, you can be an expert. Prefer to leave it to
                    the professionals? That's fine too!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Testimonials />

        <section className="cta bg-gradient">
          <div className="container">
            <div className="cta__content mt-n4">
              <h4 className="mb-0 mt-4">
                What are you waiting for? Get started!{" "}
              </h4>
              <Link to="/get-in-touch/" className="btn btn-outline-light mt-4">
                Let's go
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default HowToGetOnline;
