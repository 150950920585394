import React from "react";
import { Link } from "gatsby";

export const Packages = () => {
  return (
    <section className="bg-light text-dark dark-strip-top pt-0">
      <div className="container">
        <h2
          className="text-white-stroke heading-sm mb-5 aos-init aos-animate"
          data-aos="fade-right"
        >
          Check out our packages
        </h2>
        <div className="row pt-4">
          <div
            className="col-sm-6 mx-auto col-lg-4 mb-4 aos-init aos-animate"
            data-aos="zoom-in-up"
            data-aos-anchor-placement="top-center"
          >
            <Link to="/base/" className="card product hover-effect">
              <img
                className="card-img-top"
                src="/images/102031838_m-e1572223598501-350x500.jpg"
                alt="Popsicles"
              />
              <div
                className="card-body d-flex flex-column justify-content-between"
                data-mh="card-body"
                style={{ height: "219px" }}
              >
                <div>
                  <h5>Base</h5>
                  <p>
                    The perfect starting point for any business looking for
                    something simple and effective. It includes all of the
                    necessary features to put your business on the map.
                  </p>
                </div>
                <h6>$1200</h6>
              </div>
              <div className="card-footer">
                <div className="d-flex align-items-center justify-content-between">
                  <span>Learn more</span>

                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-sm-6 mx-auto col-lg-4 mb-4 aos-init aos-animate"
            data-aos="zoom-in-up"
            data-aos-anchor-placement="top-center"
          >
            <Link to="/grow/" className="card product hover-effect">
              <img
                className="card-img-top"
                src="/images/ice-cream-350x500.jpg"
                alt="Ice cream in cone."
              />
              <div
                className="card-body d-flex flex-column justify-content-between"
                data-mh="card-body"
                style={{ height: "219px" }}
              >
                <div>
                  <h5>Grow</h5>
                  <p>
                    Need something a little bit bigger? Look no further than our
                    Grow website package. Be found online by showcasing your
                    products and services with a multi-page website.
                  </p>
                </div>
                <h6>$3000</h6>
              </div>
              <div className="card-footer">
                <div className="d-flex align-items-center justify-content-between">
                  <span>Learn more</span>

                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="col-sm-6 mx-auto col-lg-4 mb-4 aos-init aos-animate"
            data-aos="zoom-in-up"
            data-aos-anchor-placement="top-center"
          >
            <Link to="/impress/" className="card product hover-effect">
              <img
                className="card-img-top"
                src="/images/104921455_m-e1572223560294-350x500.jpg"
                alt="Freakshake. Extreme milkshake."
              />
              <div
                className="card-body d-flex flex-column justify-content-between"
                data-mh="card-body"
                style={{ height: "219px" }}
              >
                <div>
                  <h5>Impress</h5>
                  <p>
                    Want to sell online and have all the bells and whistles?
                    Bring your vision to life with a custom website design. If
                    you can dream it, we can build it.
                  </p>
                </div>
                <h6>Free quote</h6>
              </div>
              <div className="card-footer">
                <div className="d-flex align-items-center justify-content-between">
                  <span>Learn more</span>

                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Packages;
